<template>
  <div class="reglement-facture">
    <b-button
      size="sm"
      class="button-export-style"
      title="Ajouter paiement"
      @click="handleModalAddReglement"
      v-if="checkPermission('RFTHNR')"
    >
      <font-awesome-icon icon="file-invoice-dollar" class="file-medical" />
    </b-button>
    <b-modal
      id="addReglementFacture"
      ref="addReglementFacture"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-reglement"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Nouveau paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('addReglementFacture')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitAddReglement"
          >
            <b-row>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Type Partenaire"
                  label-for="type_partenaire"
                  class="mr-2"
                >
                  <b-form-select
                    id="type_partenaire"
                    v-model="reglementToAdd.type_partenaire"
                    :options="computedTypePartenaire"
                    text-field="text"
                    value-field="value"
                    required
                    class="b-form-select-raduis"
                    @change="getClientsTypePartenaire"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="2.5" sm="2.5" md="2.5">
                <b-form-group
                  label="Partenaire"
                  label-for="client"
                  class="mr-2"
                >
                  <select-simple-with-search
                    :value="reglementToAdd.filiale_id"
                    :options="computedClients"
                    placeholder="sélectionner un Partenaire"
                    @changeValue="
                      reglementToAdd.filiale_id = $event;
                      getFacturesFiliales();
                    "
                    label="text"
                    trackBy="value"
                    :disabled="selectClient"
                    noResult="Aucun Partenaire trouvé."
                    :allowEmpty="false"
                  />
                </b-form-group>
              </b-col>
              <div class="loading" v-if="getLoaderClientReglementList">
                <div class="spinner-border loadingFetch" role="status"></div>
              </div>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Type paiement"
                  label-for="type"
                  class="mr-2"
                >
                  <b-form-select
                    v-model="reglementToAdd.type_reglement"
                    id="type"
                    :options="computedTypeReglement"
                    required
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <lcr
                :montantFix="true"
                :reglement="reglementToAdd"
                :factures="facturesReglement"
                @saveDataForLcr="saveDataForLcr"
                :resetModalLcr="resetModalLcr"
              />
              <b-col
                col
                xs="2.5"
                sm="2.5"
                md="2.5"
                v-if="!computedPaiementNameSelected.includes('LCR')"
              >
                <b-form-group
                  label="Conditions du paiement"
                  label-for="condition"
                  class="mr-2"
                >
                  <b-form-select
                    v-model="reglementToAdd.condition_paiement"
                    required
                    id="condition"
                    :options="computedConditionPaiement"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                col
                xs="2"
                sm="2"
                md="2"
                v-if="getConditionAmountNonLettre"
              >
                <b-form-group
                  label="Montant non alloué"
                  label-for="reste"
                  class="input-modal-champ col-2 p-0 mr-2 orange-color"
                >
                  <div class="text-dark custom-input">
                    {{ getAmountNonLettrerReglemnt | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                col
                :xs="getConditionAmountNonLettre ? '2' : '4'"
                :sm="getConditionAmountNonLettre ? '2' : '4'"
                :md="getConditionAmountNonLettre ? '2' : '4'"
              >
                <b-form-group
                  label="Montant à payer"
                  label-for="montant"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <b-form-input
                    id="montant"
                    @input="calculRestePayer"
                    type="number"
                    required
                    v-model="reglementToAdd.montant_a_payer"
                    min="0"
                    step="0.01"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col col xs="1.25" sm="1.25" md="1.25">
                <b-form-group label="Unité" label-for="unite" class="mr-2">
                  <b-form-select
                    id="unite"
                    :options="computedUnite"
                    text-field="text"
                    v-model="reglementToAdd.unite"
                    required
                    value-field="value"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="2.5" sm="2.5" md="2.5">
                <b-form-group
                  label="Réference"
                  label-for="ref"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <b-form-input
                    id="ref"
                    type="text"
                    v-model="reglementToAdd.ref"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Date paiement"
                  label-for="date_paiement"
                  class="input-modal-champ"
                >
                  <date-picker
                    id="date_paiement"
                    value-type="format"
                    format="DD-MM-YYYY"
                    v-model="reglementToAdd.date_paiement"
                    required
                    type="date"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Reste"
                  label-for="reste"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToAdd.reste | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Solde courant"
                  label-for="solde"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToAdd.solde_init | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Montant Echu"
                  label-for="solde"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToAdd.solde_echeance | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <badge v-if="getConditionAmountNonLettre" />
            <b-row>
              <b-col col xs="12" sm="12" md="12">
                <b-form-group
                  label="Note"
                  label-for="note"
                  class="input-modal-champ"
                ></b-form-group>
                <b-form-textarea
                  id="note"
                  row="3"
                  v-model="reglementToAdd.note"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-tabs content-class="mt-3" fill class="mt-3 tabs-class-reglement">
              <b-tab :title="'Factures à régler ' + vendeur" active>
                <div>
                  <div
                    v-if="show == true"
                    class="loading-custom-template-block"
                  >
                    <Half-circle-spinner
                      :animation-duration="1000"
                      :size="50"
                      :color="'#4d4bac'"
                      class="loeder"
                    />
                  </div>
                  <div class="body-box-setting">
                    <div class="table-rapport-style fix-rapport-style w-100-p">
                      <div class="d-flex justify-content-end mb-2">
                        <b-form-group
                          label=""
                          label-for="search-add"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-input
                            autocomplete="off"
                            v-model="search"
                            placeholder="Recherche par numéro de facture"
                            class="b-form-select-raduis"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <b-table
                        show-empty
                        id="my-table"
                        class="
                          custom-table-style
                          customTableReglementFac
                          table-header
                        "
                        :items="facturesReglementComputed"
                        :fields="fields"
                        bordered
                        sticky-header
                        hover
                        responsive
                        head-variant="light"
                        :tbody-tr-class="rowClass"
                        empty-text="Il n'y a aucun enregistrement à afficher"
                      >
                        <template class="check-th" v-slot:head()="data">
                          <div v-if="data.field.key === 'check_all'">
                            <b-form-checkbox
                              class="check-th"
                              v-model="checkAll"
                              @change="handleCheckAll"
                            >
                            </b-form-checkbox>
                          </div>
                        </template>
                        <template v-slot:cell(check_all)="data">
                          <b-form-checkbox
                            v-model="data.item.check"
                            @change="checkFacture(data.item)"
                            class="check-th"
                            :value="true"
                            :unchecked-value="false"
                          >
                          </b-form-checkbox>
                        </template>
                        <template v-slot:cell(num)="data">{{
                          data.item.num
                        }}</template>
                        <template v-slot:cell(total_ttc)="data">{{
                          data.item.total_ttc | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_regle)="data">{{
                          data.item.montant_regle | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_a_regle)="data">
                          <EditableInput
                            :editable="
                              reglementToAdd.montant_a_payer != null &&
                                data.item.check == true
                            "
                            champName="montant_a_regle"
                            :item="data.item"
                            :value="data.item.montant_a_regle"
                            type="text"
                            editableType="input"
                            :updateFunction="updateMontantRegler"
                            :defaultColor="true"
                            :float="true"
                        /></template>
                        <template v-slot:cell(reste)="data">{{
                          data.item.reste | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(vendeur)="data">{{
                          data.item.vendeur
                        }}</template>
                        <template v-slot:cell(client)="data">{{
                          data.item.client
                        }}</template>
                        <template v-slot:cell(delais_paiement)="data">{{
                          data.item.date_echeance | formateDate
                        }}</template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Fichiers jointes">
                <div class="body-box-rapport body-box-setting">
                  <div class="doc-list mt-1 box-upload">
                    <div class="form-type">
                      <div class="form-groupe">
                        <div>
                          <b-form-file
                            ref="file-type"
                            v-model="reglementToAdd.files"
                            :required="false"
                            placeholder="Aucun fichier selectionné"
                            drop-placeholder="Drop file here..."
                            multiple
                          >
                          </b-form-file>
                        </div>
                        <div id="upload-file-component" class="mt-3">
                          <div
                            class="files-to-upload"
                            v-for="(file, index) in reglementToAdd.files"
                            :key="'file' + index"
                          >
                            <p class="file-name">
                              <font-awesome-icon
                                icon="paperclip"
                                class="file-upload-icon ml-3"
                              />
                              {{ file.name }}
                            </p>
                            <p class="file-name">
                              <b-form-group
                                label="Description"
                                label-for="description"
                                class="input-modal-champ"
                              ></b-form-group>
                              <b-form-textarea
                                id="description"
                                v-model="file.description"
                              ></b-form-textarea>
                            </p>
                            <p class="file-name-trash">
                              <font-awesome-icon
                                icon="trash"
                                class="file-trash-upload-icon"
                                style="float: right"
                                @click="deleteUploadFile(index)"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="pt-3">
              <div v-if="show == true" class="loading-custom-template-block">
                <Half-circle-spinner
                  :animation-duration="1000"
                  :size="50"
                  :color="'#4d4bac'"
                  class="loeder"
                />
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-3">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import SelectSimpleWithSearch from '../../component/selectSimpleWithSearch.vue';
export default {
  data() {
    return {
      search: null,
      resetModalLcr: false,
      error: [],
      selectClient: false,
      dataLcr: null,
      checkAll: false,
      reglementToAdd: {
        filiale_id: null,
        type_partenaire: 'Client',
        type_reglement: null,
        condition_paiement: null,
        montant_a_payer: null,
        unite: 'EUR',
        ref: null,
        date_paiement: moment(Date.now()).format('DD-MM-YYYY'),
        reste: null,
        solde: null,
        solde_init: null,
        solde_echeance: null,
        note: null,
        files: []
      },
      factures: [],
      show: false,
      loading: false,
      vendeur: '',
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'width-service',
          tdClass: 'width-service'
        },
        {
          key: 'num',
          label: 'Numéro'
        },
        {
          key: 'total_ttc',
          label: 'Montant Total'
        },
        {
          key: 'reste',
          label: 'Reste'
        },
        {
          key: 'montant_regle',
          label: 'Montant Réglé'
        },
        {
          key: 'montant_a_regle',
          label: 'Montant à Régler'
        },
        {
          key: 'vendeur',
          label: 'Société'
        },
        {
          key: 'client',
          label: 'Client'
        },
        {
          key: 'delais_paiement',
          label: 'Délais du paiement'
        }
      ]
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    },
    formateValue: value => {
      if (value && value != null && value != '') {
        return parseFloat(value)?.toFixed(2);
      }
      return value;
    },
    numberWithSpaces: x => {
      // return new Intl.NumberFormat().format(x);
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'clientsReglement',
      'TypeReglement',
      'ConditionPaiement',
      'facturesReglement',
      'soldeClient',
      'soldeEcheance',
      'checkPermission',
      'getLoaderClientReglementList',
      'getAmountNonLettrerReglemnt',
      'getConditionAmountNonLettre'
    ]),
    computedPaiementNameSelected() {
      if (this.reglementToAdd && this.reglementToAdd.type_reglement != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToAdd.type_reglement
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToAdd.type_reglement + '';
    },
    facturesReglementComputed() {
      if (this.search != null && this.search != '') {
        return this.facturesReglement.filter(item =>
          item.num.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.facturesReglement;
    },
    computedCheckAll() {
      let table = [];
      if (
        this.facturesReglement.map(item => {
          if (item.check == true) {
            table.push(item);
          }
        })
      )
        if (table.length == this.facturesReglement.length) {
          return true;
        }
      return false;
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedClients() {
      return this.clientsReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },

    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    }
  },
  methods: {
    ...mapActions([
      'getClientsReglement',
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'getFactureByFilialeReglement',
      'getSoldeClient',
      'createReglement',
      'resetMutationsReglements',
      'getSoldeEcheance',
      'createReglementLcrFactureEcheances',
      'resetListPartenaire',
      'getAmountNonLettrer'
    ]),
    saveDataForLcr(data) {
      this.dataLcr = data;
    },
    handleModalAddReglement() {
      this.$refs['addReglementFacture'].show();
      this.getClientsReglement({
        type_partenaire: this.reglementToAdd.type_partenaire
      });
    },
    async getClientsTypePartenaire() {
      this.resetMutationsReglements();
      this.reglementToAdd.solde = null;
      this.reglementToAdd.solde_echeance = null;
      this.selectClient = true;
      const response = await this.getClientsReglement({
        type_partenaire: this.reglementToAdd.type_partenaire
      });
      if (response) {
        this.selectClient = false;
        this.reglementToAdd.filiale_id = null;
      }
    },
    updateMontantRegler(payload) {
      this.reglementToAdd.reste = parseFloat(this.reglementToAdd.reste);
      this.reglementToAdd.solde = parseFloat(this.reglementToAdd.solde);
      if (parseFloat(payload.value) == 0) {
        payload.row.check = false;
      }
      if (
        parseFloat(this.reglementToAdd.reste) +
          parseFloat(payload.row.montant_a_regle) >
        parseFloat(payload.value)
      ) {
        let ancien_montant_a_regle = 0;
        ancien_montant_a_regle = payload.row.montant_a_regle;
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - parseFloat(payload.value).toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        this.reglementToAdd.reste =
          this.reglementToAdd.reste + parseFloat(ancien_montant_a_regle);
        this.reglementToAdd.reste =
          this.reglementToAdd.reste - parseFloat(payload.value);
        this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
        this.reglementToAdd.solde =
          this.reglementToAdd.solde + parseFloat(ancien_montant_a_regle);
        this.reglementToAdd.solde =
          this.reglementToAdd.solde - parseFloat(payload.value);
        this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
      }
    },
    deleteUploadFile(index) {
      this.reglementToAdd.files.splice(index, 1);
    },
    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglementComputed.filter(
          item => item.check == false
        );
        table.map(item => {
          if (this.reglementToAdd.reste > 0) {
            item.check = true;
            this.checkFacture(item);
          }
        });
      } else {
        let table = this.facturesReglementComputed.filter(
          item => item.check == true
        );
        table.map(item => {
          item.check = false;
          this.checkFacture(item);
        });
      }
    },
    checkFacture(item) {
      let dataCheck = this.facturesReglement.filter(
        i => i.check == true && i.id != item.id
      );
      if (
        dataCheck.length == 0 ||
        (dataCheck.length > 0 && dataCheck[0].vendeur_id == item.vendeur_id)
      ) {
        this.reglementToAdd.reste = parseFloat(this.reglementToAdd.reste);
        this.reglementToAdd.solde = parseFloat(this.reglementToAdd.solde);
        if (this.reglementToAdd.reste > 0) {
          if (item.check == true) {
            if (this.reglementToAdd.reste > parseFloat(item.resteInitial)) {
              this.reglementToAdd.reste =
                this.reglementToAdd.reste - parseFloat(item.resteInitial);
              this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
              item.reste = 0;
              item.montant_a_regle =
                parseFloat(item.montant_a_regle) +
                parseFloat(item.resteInitial);
              this.reglementToAdd.solde =
                this.reglementToAdd.solde - parseFloat(item.montant_a_regle);
              this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
              this.reglementToAdd.solde = Math.abs(this.reglementToAdd.solde);
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            } else {
              item.montant_a_regle = this.reglementToAdd.reste.toFixed(2);
              item.reste =
                parseFloat(item.resteInitial) - this.reglementToAdd.reste;
              item.reste = item.reste.toFixed(2);
              this.reglementToAdd.reste = 0;
              this.reglementToAdd.solde =
                parseFloat(this.reglementToAdd.solde) -
                parseFloat(item.montant_a_regle);
              this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
              this.reglementToAdd.solde = Math.abs(this.reglementToAdd.solde);
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            }
          } else {
            this.reglementToAdd.reste =
              this.reglementToAdd.reste + parseFloat(item.montant_a_regle);
            this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
            this.reglementToAdd.solde =
              this.reglementToAdd.solde + parseFloat(item.montant_a_regle);
            this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            if (this.computedCheckAll == true) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
          }
        } else {
          if (item.check == false) {
            this.reglementToAdd.reste =
              parseFloat(this.reglementToAdd.reste) +
              parseFloat(item.montant_a_regle);
            this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
            this.reglementToAdd.solde = parseFloat(
              this.reglementToAdd.solde
            ).toFixed(2);
            this.reglementToAdd.solde =
              parseFloat(this.reglementToAdd.solde) +
              parseFloat(item.montant_a_regle);
            this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);

            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            this.checkAll = false;
          } else {
            item.check = false;
            this.checkAll = false;
          }
        }
      } else {
        item.check = !item.check;
      }
    },
    resetModal() {
      this.reglementToAdd = {
        filiale_id: null,
        type_partenaire: 'Client',
        type_reglement: null,
        condition_paiement: null,
        montant_a_payer: null,
        unite: 'EUR',
        ref: null,
        date_paiement: moment(Date.now()).format('DD-MM-YYYY'),
        reste: null,
        solde: null,
        solde_echeance: null,
        note: null,
        files: []
      };
      this.show = false;
      this.loading = false;
      this.vendeur = '';
      this.error = [];
      this.resetListPartenaire([]);
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.resetMutationsReglements();
    },
    calculRestePayer() {
      this.reglementToAdd.reste = this.reglementToAdd.montant_a_payer;
      let TableF = this.facturesReglement?.filter(item => item.check == false);
      if (TableF.length != this.facturesReglement.length) {
        this.getFacturesFiliales();
      }
    },
    async getFacturesFiliales() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToAdd.filiale_id,
        type_partenaire: this.reglementToAdd.type_partenaire
      });
      if (response) {
        this.clientsReglement.map(item => {
          if (item.id == this.reglementToAdd.filiale_id) {
            this.vendeur = item.name;
            this.reglementToAdd.type_reglement = item.payment_type_id;
            this.reglementToAdd.condition_paiement = item.payment_condition_id;
          }
        });
        const responseSld = await this.getSoldeClient({
          id: this.reglementToAdd.filiale_id,
          type_partenaire: this.reglementToAdd.type_partenaire
        });
        if (responseSld) {
          this.reglementToAdd.solde = this.soldeClient;
          this.reglementToAdd.solde_init = this.soldeClient;
          this.getAmountNonLettrer({
            filiale_id: this.reglementToAdd.filiale_id,
            type_partenaire: this.reglementToAdd.type_partenaire
          });
          const responseSldEch = await this.getSoldeEcheance({
            id: this.reglementToAdd.filiale_id,
            type_partenaire: this.reglementToAdd.type_partenaire
          });
          if (responseSldEch) {
            this.reglementToAdd.solde_echeance = this.soldeEcheance;
            this.show = false;
          }
        }
      }
    },
    async handleSubmitAddReglement() {
      if (this.reglementToAdd.montant_a_payer == 0) {
        this.error = 'If faut avoir le montant à payer supèrieur à 0';
      } else {
        this.loading = true;
        this.error = [];
        let bodyFormData = new FormData();
        bodyFormData.append('balance', this.reglementToAdd.reste);
        bodyFormData.append('amount', this.reglementToAdd.montant_a_payer);
        bodyFormData.append('currency', this.reglementToAdd.unite);
        bodyFormData.append('payment_date', this.reglementToAdd.date_paiement);
        bodyFormData.append('type_id', this.reglementToAdd.type_reglement);
        if (!this.computedPaiementNameSelected.includes('LCR')) {
          bodyFormData.append(
            'payment_condition_id',
            this.reglementToAdd.condition_paiement
          );
        }
        bodyFormData.append('filiale_id', this.reglementToAdd.filiale_id);
        bodyFormData.append('payment_note', this.reglementToAdd.note);
        bodyFormData.append('reference', this.reglementToAdd.ref);
        bodyFormData.append(
          'type_partenaire',
          this.reglementToAdd.type_partenaire
        );

        for (let i = 0; i < this.facturesReglement.length; i++) {
          if (this.facturesReglement[i].check == true) {
            bodyFormData.append(
              'factures[' + i + '][id]',
              this.facturesReglement[i].id
            );
            bodyFormData.append(
              'factures[' + i + '][montant_regle]',
              this.facturesReglement[i].montant_a_regle
            );
            bodyFormData.append(
              'factures[' + i + '][total_ttc]',
              this.facturesReglement[i].total_ttc
            );
          }
        }
        for (let i = 0; i < this.reglementToAdd.files?.length; i++) {
          bodyFormData.append(
            'files[' + i + '][file]',
            this.reglementToAdd.files[i]
          );
          bodyFormData.append(
            'files_descriptions[' + i + ']',
            this.reglementToAdd.files[i]['description']
          );
        }
        const response = await this.createReglement(bodyFormData);
        if (response.success) {
          if (
            this.reglementToAdd.type_reglement != null &&
            this.dataLcr &&
            this.dataLcr.lcr &&
            this.dataLcr.lcr.length != 0 &&
            this.computedPaiementNameSelected.includes('LCR')
          ) {
            let bodyFormDataEch = new FormData();
            this.dataLcr?.lcr?.forEach((element, i) => {
              bodyFormDataEch.append(
                'echeances[' + i + '][libelle]',
                element.libelle
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][client_id]',
                this.dataLcr.client_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][date_echeance]',
                element.date
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][beneficiaire_id]',
                this.dataLcr.beneficiaire_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][valeur_en]',
                this.dataLcr.valeur_en
              );
              bodyFormDataEch.append('echeances[' + i + '][date_envoi]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][date_signature]',
                null
              );
              bodyFormDataEch.append('echeances[' + i + '][date_valeur]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][devise]',
                this.dataLcr.devise
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][amount]',
                element.value
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][rib_id]',
                this.dataLcr.rib.id
              );
            });
            const resEch = await this.createReglementLcrFactureEcheances({
              response: response.response,
              data: bodyFormDataEch
            });
            if (resEch) {
              this.resetModalLcr = !this.resetModalLcr;
              this.hideModal('addReglementFacture');
              this.loading = false;
            }
          } else {
            this.resetModalLcr = !this.resetModalLcr;
            this.hideModal('addReglementFacture');
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      }
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    lcr: () => import('@/views/component/reglement/lcr.vue'),
    badge: () => import('@/views/component/badge.vue'),
    SelectSimpleWithSearch
  },
  mounted() {
    // this.getClientsReglement({ type_partenaire: 'Client' });
  }
};
</script>

<style lang="scss" scoped>
.loading {
  .loadingFetch {
    width: 16px;
    height: 16px;
    font-size: 10px;
    color: black;
    margin-top: 32px;
  }
}

.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}

.reglement-facture {
  .file-medical {
    font-size: 13px;
  }
  .color-text {
    color: black;
  }
  .list-type {
    list-style-type: none;
  }
}
</style>
<style lang="scss">
.form-type {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        // background-color: #8d8cb7;
        padding: 3px;
      }

      .file-name-title {
        width: 80%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-name-trash {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 20%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }

      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}
#upload-file-component {
  overflow-y: auto;
  max-height: 321px;
  position: relative;
}

.doc-list {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
.tabs-class-reglement {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
.custom-input {
  width: 100% !important;
  height: 31px;
  border-radius: 17px;
  padding-top: 5px;
  background-color: #7070701c;
}
.fix-rapport-style {
  display: block !important;
}

.table-rapport-style .customTableReglementFac {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 8.5px;
    padding: 2px 2px !important;
    min-width: 13%;
    width: 13%;
  }
  .width-service {
    min-width: 40px;
    width: 40px;
  }
  .b-table-sticky-header {
    max-height: 413px;
  }
}

.cutsom-modal-bootstrap-reglement .modal-dialog {
  top: 0% !important;
  height: calc(100vh - 32px) !important;
  max-width: 90% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-reglement .modal-dialog {
    top: 0%;
    max-width: 90%;
    height: 100% !important;
    margin: auto;
  }
}
</style>
